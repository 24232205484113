<template>
<div>
  <h1 class="m-2">User Media Recorder</h1>
  <b-card bg-variant="light m-2">
    <h2>Options</h2>
    <b-checkbox v-model="mixAudio">Mix Audio (screen capture audio and microphone) or Separate Audio</b-checkbox>
    <b-checkbox v-model="limitScreenSize">Limit screen size to 480p/15fps</b-checkbox>
    <b-checkbox v-model="autoRecordOnCapture">Automatically records on capture</b-checkbox>
    <b-checkbox v-model="timeStampBucket">Append bucket name with a timestamp</b-checkbox>
  </b-card>
  <b-card bg-variant="light m-2">
    <user-media ref="usermedia" @user-update="(u) => localUser = u"/>
    <div class="form-inline sub-mr-2 sub-mt-2">
      <audioviz :stream="localUser.stream" v-if="localUser"/>
      <div>Microphone volume</div>
    </div>
    <div class="form-inline sub-mr-2 sub-mt-2">
      <audioviz :stream="localUser.screenStream" v-if="localUser"/>
      <div>Screen volume</div>
      <b-alert variant="warning mb-0" :show="$refs.recorder && !$refs.recorder.recording">
        This is not recording!
      </b-alert>
    </div>
  </b-card>
  <b-card bg-variant="light m-2">
    <stream-recorder ref="recorder"/>
    <stream-recorder ref="recorder2" v-if="!mixAudio"/>
    <div class="form-inline sub-mr-2 sub-mt-2">
      <div>Recording Bucket:</div>
      <b-input class="mb-2 mr-sm-2 mb-sm-0 w-50" size="sm" v-model="recorderConfig.hlsBucket" :disabled="timeStampBucket"></b-input>
    </div>
    <div class="form-inline sub-mr-2 sub-mt-2">
      <button class="btn btn-primary" @click="captureScreen();">Capture Screen</button>
      <button class="btn btn-primary" @click="start();" :disabled="($refs.recorder && $refs.recorder.recording) || (localUser && !localUser.screenStream)">Record</button>
      <b-spinner ref="liveIndic" variant="danger" type="grow" small v-if="$refs.recorder && $refs.recorder.recording"/>
      <button class="btn btn-primary" @click="stop();" :disabled="$refs.recorder && !$refs.recorder.recording">Stop</button>
    </div>
  </b-card>
</div>
</template>

<script>
import { getLog, setDbLog } from "@/services/log";
let log = getLog('test-um-rec');
import streamRecorder from "@/components/streamRecorder.vue";
import UserMedia from '@/components/userMedia.vue';
import Audioviz from '@/components/audioviz.vue';
import { mergeAudioTrack } from '@/components/video-stream-merger';
import { getStreamInfo } from '@/services/mediautils';
import { mergeDeep } from '@/services/utils';
import moment from 'moment';
//import { createRoomWithData } from '../../services/room';

export default {
  components: {
    streamRecorder,
    UserMedia,
    Audioviz,
  },
  data() {
    return {
      // Options
      limitScreenSize: true,
      mixAudio: true,
      autoRecordOnCapture: true,
      timeStampBucket: true,

      // Variables
      localUser: null,

      // Settings
      screenConstraints: {
        video: {
          cursor: "always",
        },
        "audio": true,
      },
      screenConstraints480p: {
        video: {
          "frameRate": 15,
          "height": 480,
          "resizeMode": "crop-and-scale",
          "width": 853,
        }
      },
      recorderConfig: {
        "mediaRecorderInterval": "5000",
        "wsIp": "gs://hh-streams/recordings",
        "mimeType": "(autodetect)",
        "hlsBucket": "rec"
      },
    }
  },
  mounted() {
    //this.$debug.isOn = true;
    let channel = "testUMR-" + moment(new Date().toISOString()).format("YYYY-MM-DD-HHmm");
    //createRoomWithData()
    setDbLog(log, channel, "user1");
  },
  methods: {
    async captureScreen() {
      let constraints = this.limitScreenSize 
        ? mergeDeep(this.screenConstraints, this.screenConstraints480p)
        : this.screenConstraints;
      log.log("captureScreen", constraints);
      let stream = await this.$refs.usermedia.shareScreen(constraints);
      if (stream)
        this.start();
    },
    start() {
      log.log("start");
      if (this.timeStampBucket) {
        this.recorderConfig.hlsBucket += "-" + moment(new Date().toISOString()).format("YYYY-MM-DD-HHmm");
      }
      if (this.mixAudio) {
        this.screenStream = mergeAudioTrack(this.localUser.screenStream, this.localUser.stream); 
        log.log("mixedStream", getStreamInfo(this.screenStream));
        this.$refs.recorder.start(this.recorderConfig, this.screenStream);
      }
      else {
        this.$refs.recorder.start(this.recorderConfig, this.localUser.screenStream);
        let recorderConfig2 = Object.assign({}, this.recorderConfig);
        recorderConfig2.hlsBucket += "-audio";
        this.$refs.recorder2.start(recorderConfig2, this.localUser.stream);
      }
    },
    stop() {
      this.$refs.recorder.stop();
      this.$refs.recorder2?.stop();
    }
  }
}
</script>

<style>

</style>